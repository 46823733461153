<template>
    <div class="con-wrap">
        <CarrotTitle title="공지사항">
            - 첨부파일은 각각 최대 2MB까지 파일을 올리실 수 있습니다.
        </CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div>
                    <div class="mb-20">
                        <span class="float-right">＊필수 입력 항목입니다.</span>
                        <div class="clear"></div>
                    </div>
                    <table class="table-row">
                        <colgroup>
                            <col width="180">
                            <col width="*">
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>상단고정</th>
                                <td>
                                    <label><input type="checkbox" name="topfix" v-model="bbs.topfix"><span class="ml-5"> 사용</span></label>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 제목</th>
                                <td>
                                    <input type="text" v-model="bbs.title" class="w-100per">
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 내용</th>
                                <td>
                                    <!-- <textarea v-model="bbs.content" class="w-100per h-300px"></textarea> -->
                                    <CarrotEditor etype="notice" v-model="bbs.content" class="w-100per h-400px"></CarrotEditor>
                                </td>
                            </tr>
                            <tr>
                                <th>파일첨부</th>
                                <td>
                                    <CarrotFileUploader ref="file1"></CarrotFileUploader>
                                    <CarrotFileUploader ref="file2" class="mt-5"></CarrotFileUploader>
                                    <CarrotFileUploader ref="file3" class="mt-5"></CarrotFileUploader>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <button class="btn-default float-left mt-30" @click="bbs.showList">목록</button>
                    <button class="btn-default float-right mt-30" @click="bbs.onSave">새글 작성하기</button>
                    <div class="clear"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, onUnmounted, reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import CarrotEditor from '@/components/common/CarrotEditor.vue'


export default {
    layout:"CARROTZone",
    components: {
        CarrotEditor
    },
    setup() {
        const router = useRouter();
        const toast  = useToast()

        const file1 = ref(null);
        const file2 = ref(null);
        const file3 = ref(null);
        const bbs = reactive({
            topfix: false,
            title : "",
            content: "",

            showList: () => {
                router.push({ name:"CARROTZone-NoticeList" });
            },

            onSave: () => {
                if( !bbs.title ){
                    toast.error("제목을 입력하세요.");
                    return;
                }
                if( !bbs.content ){
                    toast.error("내용을 입력하세요.");
                    return;
                }
                var formData = new FormData();
                formData.append("topfix", bbs.topfix==true?"Y":"N");
                formData.append("title", bbs.title);
                formData.append("content", bbs.content);
                if(file1.value.file.filedata!==null) {
                    formData.append("nfile[]", file1.value.file.filedata);
                }
                if(file2.value.file.filedata!==null) {
                    formData.append("nfile[]", file2.value.file.filedata);
                }
                if(file3.value.file.filedata!==null) {
                    formData.append("nfile[]", file3.value.file.filedata);
                }
                axios.post('/rest/carrotzone/noticeAdd', formData, {
                    headers: {
                    'Content-Type': 'multipart/form-data'
                    }
                }).then((res)=>{
                    if( res.data.err == 0 ){
                        router.push({ 
                            name:"CARROTZone-NoticeView-idx",
                            params : { idx:res.data.idx }
                        });
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                })
            }
        });
        
        onMounted(() => {
            // Mounted
        });

        onUnmounted(() => { 
            // UnMounted
        });
        return {bbs, file1, file2, file3};
    }
}
</script>

<style lang="scss" scoped>
</style>